import React from 'react';
import MuiBox from '../../../reusableComponents/MuiBox';
import MuiButton from '../../../reusableComponents/MuiButton';
import { greyColorText, redColor, yellowColor } from '../../../helper/colors';
import MuiTypography from '../../../reusableComponents/MuiTypography';
import { v4 as uuidv4 } from 'uuid';
import ucionica1 from '../../../assets/banners/ucionica1.webp';
import ucionica2 from '../../../assets/banners/ucionica2.webp';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

let theeretical = [
  'O saobraćajnim propisima.',
  'Pravilima i ponašanju u saobraćaju.',
  'Saobraćajnim znakovima.',
  'Pravilnom ponašanju u različitim saobraćajnim situacijama.',
  'Kao i o opasnostima na putu.',
];
const TheoreticalTraining = () => {
  const navigate = useNavigate();
  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <Helmet>
        <title>Teorijska obuka vozača - Auto škola DUŠAN START</title>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Saznajte sve o teorijskoj obuci za vozače u Auto školi DUŠAN START u Smederevu. Nudimo detaljnu pripremu za sve kategorije vozačkih dozvola: A, A1, A2, AM, B, C, CE, uz stručne instruktore i savremene metode obuke.'
        />
        <meta
          name='keywords'
          content='auto škola, Dušan Start, Smederevo, teorijska obuka, vozačka dozvola, vozački ispit, kategorije A, A1, A2, AM, B, C, CE, priprema za vozače, instrukcije'
        />
        <meta
          name='author'
          content='Marko Stojanovic'
        />
        <link
          rel='canonical'
          href='https://www.dusanstartautoskola.rs/theoreticalTraining'
        />

        <meta
          property='og:title'
          content='Teorijska obuka vozača - Auto škola DUŠAN START'
        />
        <meta
          property='og:description'
          content='Saznajte sve o teorijskoj obuci za vozače u Auto školi DUŠAN START u Smederevu. Nudimo detaljnu pripremu za sve kategorije vozačkih dozvola: A, A1, A2, AM, B, C, CE, uz stručne instruktore i savremene metode obuke.'
        />
        <meta
          property='og:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          property='og:url'
          content='https://www.dusanstartautoskola.rs/theoreticalTraining'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto škola DUŠAN START'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content='Teorijska obuka vozača - Auto škola DUŠAN START'
        />
        <meta
          name='twitter:description'
          content='Saznajte sve o teorijskoj obuci za vozače u Auto školi DUŠAN START u Smederevu. Nudimo detaljnu pripremu za sve kategorije vozačkih dozvola: A, A1, A2, AM, B, C, CE, uz stručne instruktore i savremene metode obuke.'
        />
        <meta
          name='twitter:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          name='twitter:site'
          content='@twitterhandle'
        />
        <meta
          name='twitter:creator'
          content='@twitterhandle'
        />
      </Helmet>

      <MuiBox
        sx={{
          backgroundImage: `url(${ucionica2})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <MuiTypography
          text='Obuka vozača A, A1, A2, AM, B, C, CE kategorija'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        />
        <MuiTypography
          text='Teorijska obuka'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem', lg: '2.3rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        />
        <MuiTypography
          text='Teorijska obuka vozača A, A1, A2, AM, B, C, CE kategorija'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        />
      </MuiBox>
      <MuiBox
        sx={{
          width: {
            xs: '95%',
            sm: '90%',
            md: '80%',
            lg: '80%',
          },
          margin: '2rem auto',
        }}>
        <MuiTypography
          text='Teorijska obuka za kandidate u auto-školama je bitna jer pruža osnovna znanja.'
          sx={[
            textStyle,
            {
              marginBottom: '1rem',
              marginTop: '1rem',
              fontWeight: 'bold',
              color: redColor,
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.4rem',
                lg: '1.4rem',
              },
            },
          ]}
        />
        {theeretical.map((item) => (
          <MuiTypography
            key={uuidv4()}
            text={`- ${item}`}
            sx={[
              textStyle,
              {
                textIndent: { xs: 0, sm: 0, md: '1rem', lg: '1rem' },
              },
            ]}
          />
        ))}

        <MuiTypography
          text='Ova obuka je važna jer pomaže kandidatima da se pripreme za vozački ispit, ali i da postanu odgovorni i bezbedni učesnici u saobraćaju.'
          sx={[
            textStyle,
            {
              marginBottom: '2rem',
              marginTop: '2rem',
              fontWeight: 'bold',
              color: '#fff',
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.4rem',
                lg: '1.4rem',
              },
              backgroundColor: yellowColor,
              padding: '1rem',
              borderRadius: '1rem',
              textAlign: 'center',
              textShadow: `0 0 0px ${yellowColor}`,
            },
          ]}
        />

        <MuiTypography
          text='Teorijska nastava u našoj skoli se završava za samo 13 radnih dana!!!'
          sx={[
            textStyle,
            {
              marginBottom: '1rem',
              marginTop: '1rem',
              fontWeight: 'bold',
              color: redColor,
              fontSize: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '1.4rem',
                lg: '1.4rem',
              },
            },
          ]}
        />

        <MuiTypography
          text='Odrzava se radnim danima(pon-petak) u dva termina:'
          sx={[
            textStyle,
            {
              textIndent: { xs: 0, sm: 0, md: '1rem', lg: '1rem' },
            },
          ]}
        />
        <MuiTypography
          text='1.Pre podne  09h '
          sx={[
            textStyle,
            {
              textIndent: { xs: 0, sm: 0, md: '1rem', lg: '1rem' },
            },
          ]}
        />
        <MuiTypography
          text='2.Po podne 17:30h'
          sx={[
            textStyle,
            {
              textIndent: { xs: 0, sm: 0, md: '1rem', lg: '1rem' },
            },
          ]}
        />

        <MuiTypography
          text='1h traje 45 min,izmedju časova je pauza od 10 minuta.'
          sx={[
            textStyle,
            {
              textIndent: { xs: 0, sm: 0, md: '1rem', lg: '1rem' },
            },
          ]}
        />

        <img
          loading='lazy'
          src={ucionica1}
          alt='classroom'
          style={{
            width: '100%',
            margin: '2.5rem auto',
            borderRadius: '1rem',
            display: 'block',
          }}
        />
        <MuiTypography
          text='Kandidati imaju mogućnost da sami izaberu termin za teorijsku obuku u skladu sa svojim rasporedom. Održava se u dva termina kako bi se omogućilo većem broju kandidata da prisustvuju.'
          sx={[
            textStyle,
            {
              textAlign: 'center',
              margin: '2rem auto',
            },
          ]}
        />
        <MuiTypography
          text='Trebalo bi da se svi časovi teorijske obuke odlusaju u kontinuitetu, jer ako se preskoći neki od časova,čeka se sledeca grupa da bi se taj čas odslusao.'
          sx={[
            textStyle,
            {
              textAlign: 'center',
              margin: '2rem auto',
            },
          ]}
        />
        <MuiTypography
          text='Broj časova teorijske obuke za B kategoriju je 40h.'
          sx={[
            textStyle,
            {
              textAlign: 'center',
              margin: '2rem auto',
            },
          ]}
        />
        <MuiTypography
          text='Broj časova teorijske obuce za C kategoriju je 7h.'
          sx={[
            textStyle,
            {
              textAlign: 'center',
              margin: '2rem auto',
            },
          ]}
        />
        <MuiTypography
          text='Broj časova za A ketegoriju u zavisnoti od ostalih kategorija.'
          sx={[
            textStyle,
            {
              textAlign: 'center',
              margin: '2rem auto',
            },
          ]}
        />
      </MuiBox>
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
        }}>
        <MuiButton
          variant='outlined'
          text='PRIJAVI SE ONLINE'
          onClick={() => navigate('/checkIn')}
          size='large'
          sx={{
            color: redColor,
            borderColor: redColor,
            fontWeight: 'bold',
            '&:hover': {
              borderColor: redColor,
              boxShadow: `0 0 5px 1px ${redColor}`,
            },
          }}
        />
      </MuiBox>
    </MuiBox>
  );
};

export default TheoreticalTraining;

let textStyle = {
  color: greyColorText,
  fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.2rem' },
  textAlign: 'left',
  marginBottom: '1rem',
};
