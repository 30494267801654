import React from 'react';
import MuiBox from '../../../reusableComponents/MuiBox';
import MuiButton from '../../../reusableComponents/MuiButton';
import { greyColorText, redColor } from '../../../helper/colors';
import MuiTypography from '../../../reusableComponents/MuiTypography';
import { useNavigate } from 'react-router-dom';
import prvaPomocBanner from '../../../assets/banners/prvaPomocBanner.webp';
import { Helmet } from 'react-helmet';

const FirstAid = () => {
  const navigate = useNavigate();
  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <Helmet>
        <title>Prva pomoć - Auto škola DUŠAN START</title>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Saznajte ključne informacije o prvoj pomoći koje su esencijalne za vozače. Auto škola DUŠAN START pruža detaljan vodič i savete za pružanje prve pomoći u hitnim situacijama.'
        />
        <meta
          name='keywords'
          content='auto škola, Dušan Start, Smederevo, prva pomoć, vozači, hitne situacije, osnovna prva pomoć, pružanje pomoći'
        />
        <meta
          name='author'
          content='Marko Stojanovic'
        />
        <link
          rel='canonical'
          href='https://www.dusanstartautoskola.rs/firstAid'
        />

        <meta
          property='og:title'
          content='Prva pomoć - Auto škola DUŠAN START'
        />
        <meta
          property='og:description'
          content='Saznajte ključne informacije o prvoj pomoći koje su esencijalne za vozače. Auto škola DUŠAN START pruža detaljan vodič i savete za pružanje prve pomoći u hitnim situacijama.'
        />
        <meta
          property='og:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          property='og:url'
          content='https://www.dusanstartautoskola.rs/firstAid'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto škola DUŠAN START'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content='Prva pomoć - Auto škola DUŠAN START'
        />
        <meta
          name='twitter:description'
          content='Saznajte ključne informacije o prvoj pomoći koje su esencijalne za vozače. Auto škola DUŠAN START pruža detaljan vodič i savete za pružanje prve pomoći u hitnim situacijama.'
        />
        <meta
          name='twitter:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          name='twitter:site'
          content='@twitterhandle'
        />
        <meta
          name='twitter:creator'
          content='@twitterhandle'
        />
      </Helmet>

      <MuiBox
        sx={{
          backgroundImage: `url(${prvaPomocBanner})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <MuiTypography
          text='Obuka vozača A, A1, A2, AM, B, C, CE kategorija'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        />
        <MuiTypography
          text='Prva pomoć'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem', lg: '2.3rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        />
        <MuiTypography
          text='Polaganje Prve pomoci - sve sto treba da znate'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        />
      </MuiBox>
      <MuiBox
        sx={{
          width: {
            xs: '95%',
            sm: '90%',
            md: '80%',
            lg: '80%',
          },
          margin: '2rem auto',
        }}>
        <MuiTypography
          text='Obuka prve pomoći obično uključuje teorijski deo gde se uči o osnovnim postupcima pružanja prve pomoći, prepoznavanju hitnih situacija i pravilnom reagovanju. Nakon teorijskog dela, obuka obično uključuje i praktične vežbe gde polaznici imaju priliku da primene stečeno znanje na realističnim simulacijama. Važno je da obuka prve pomoći bude interaktivna i edukativna kako bi polaznici stekli neophodne veštine za pravilno reagovanje u hitnim situacijama.'
          sx={[
            textStyle,
            {
              textAlign: 'left',
              margin: '2rem auto',
            },
          ]}
        />
        <MuiTypography
          text='Ova odluka je na Vama – da li ćete obuku proći u našoj auto školi, u saradnji sa zdravstvenom ustanovom, ili ćete nam Vi dostavite uverenje.'
          sx={[
            textStyle,
            {
              textAlign: 'left',
              margin: '2rem auto',
            },
          ]}
        />
      </MuiBox>
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
        }}>
        <MuiButton
          variant='outlined'
          text='PRIJAVI SE ONLINE'
          onClick={() => navigate('/checkIn')}
          size='large'
          sx={{
            color: redColor,
            borderColor: redColor,
            fontWeight: 'bold',
            '&:hover': {
              borderColor: redColor,
              boxShadow: `0 0 5px 1px ${redColor}`,
            },
          }}
        />
      </MuiBox>
    </MuiBox>
  );
};

export default FirstAid;

let textStyle = {
  color: greyColorText,
  fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem', lg: '1.2rem' },
  textAlign: 'left',
  marginBottom: '1rem',
};
