import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MuiBox from '../../reusableComponents/MuiBox';
import MuiTypography from '../../reusableComponents/MuiTypography';
import { greyColorText, redColor, yellowColor } from '../../helper/colors';
import { v4 as uuidv4 } from 'uuid';
import Divider from '@mui/material/Divider';
import MuiButton from '../../reusableComponents/MuiButton';
import { AppBar, Chip, Tab, Tabs } from '@mui/material';
import MuiGrid from '../../reusableComponents/MuiGrid';
import {
  categorieA,
  categorieB,
  categorieC,
} from '../../helper/categoriesData';
import DriveParkSLider from '../DriveParkSLider';
import { Helmet } from 'react-helmet';

const Categories = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [state, setState] = useState(null);
  const [value, setValue] = useState(0);
  const [heightOfDivCards, setHeightOfDivsCards] = useState('180px');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}>
        {value === index && <MuiBox sx={{ p: 3 }}>{children}</MuiBox>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    setState(null);
    if (id === 'A') {
      setState(categorieA);
    } else if (id === 'B') {
      setState(categorieB);
    } else if (id === 'C') {
      setState(categorieC);
    } else {
      setState(null);
      navigate('*');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let heightOfDivs = [];

    if (state !== null) {
      let elementi = document.querySelectorAll('[id*="idOdGrida"]');
      elementi.forEach(function (element) {
        heightOfDivs.push(element.offsetHeight);
      });
      let maxHeight = Math.max(...heightOfDivs);
      setHeightOfDivsCards(maxHeight + 'px');
    }
  }, [state, id, value]);

  const categoryDetails = {
    A: {
      title: 'Kategorija A - Auto škola DUŠAN START',
      description:
        'Saznajte sve o polaganju vozačkog ispita za kategoriju A u Auto školi DUŠAN START u Smederevu. Pridružite nam se i postanite odgovoran vozač motocikla.',
      url: 'https://www.dusanstartautoskola.rs/categories/A',
      keywords:
        'auto škola, Dušan Start, Smederevo, kategorija A, vozačka dozvola, vozački ispit, obuka, vozačka škola, motocikl',
    },
    B: {
      title: 'Kategorija B - Auto škola DUŠAN START',
      description:
        'Saznajte sve o polaganju vozačkog ispita za kategoriju B u Auto školi DUŠAN START u Smederevu. Pridružite nam se i postanite odgovoran vozač automobila.',
      url: 'https://www.dusanstartautoskola.rs/categories/B',
      keywords:
        'auto škola, Dušan Start, Smederevo, kategorija B, vozačka dozvola, vozački ispit, obuka, vozačka škola, automobil',
    },
    C: {
      title: 'Kategorija C - Auto škola DUŠAN START',
      description:
        'Saznajte sve o polaganju vozačkog ispita za kategoriju C u Auto školi DUŠAN START u Smederevu. Pridružite nam se i postanite odgovoran vozač kamiona.',
      url: 'https://www.dusanstartautoskola.rs/categories/C',
      keywords:
        'auto škola, Dušan Start, Smederevo, kategorija C, vozačka dozvola, vozački ispit, obuka, vozačka škola, kamion',
    },
  };

  const currentCategory = categoryDetails[id];

  return (
    <>
      <Helmet>
        <title>{currentCategory.title}</title>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content={currentCategory.description}
        />
        <meta
          name='keywords'
          content={currentCategory.keywords}
        />
        <meta
          name='author'
          content='Marko Stojanovic'
        />
        <link
          rel='canonical'
          href={currentCategory.url}
        />

        <meta
          property='og:title'
          content={currentCategory.title}
        />
        <meta
          property='og:description'
          content={currentCategory.description}
        />
        <meta
          property='og:image'
          content='%PUBLIC_URL%/logo512.png'
        />
        <meta
          property='og:url'
          content={currentCategory.url}
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto škola DUŠAN START'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content={currentCategory.title}
        />
        <meta
          name='twitter:description'
          content={currentCategory.description}
        />
        <meta
          name='twitter:image'
          content='%PUBLIC_URL%/logo512.png'
        />
        <meta
          name='twitter:site'
          content='@twitterhandle'
        />
        <meta
          name='twitter:creator'
          content='@twitterhandle'
        />
      </Helmet>
      {id && state !== null && (
        <MuiBox sx={{ padding: '0 0.5rem' }}>
          <MuiBox
            sx={{
              backgroundImage: `url(${state.banner})`,
              backgroundSize: 'cover',
              height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <MuiTypography
              text={state.bannerText1}
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                fontSize: {
                  xs: '1.2rem',
                  sm: '1.2rem',
                  md: '1.5rem',
                  lg: '2rem',
                },
                textAlign: 'center',
                marginBottom: '1.5rem',
                textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
              }}
            />
            <MuiTypography
              text={state.bannerText2}
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                fontSize: {
                  xs: '1.5rem',
                  sm: '1.5rem',
                  md: '2rem',
                  lg: '2.3rem',
                },
                textAlign: 'center',
                marginBottom: '1.5rem',
                textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
              }}
            />
          </MuiBox>
          <MuiBox>
            <MuiTypography
              text='DOSTUPNE KATEGORIJE ZA POLAGANJE'
              sx={{
                color: yellowColor,
                fontWeight: 'bold',
                fontSize: {
                  xs: '1.5rem',
                  sm: '1.5rem',
                  md: '2rem',
                  lg: '2.3rem',
                },
                textAlign: 'center',
                margin: '1.5rem auto',
              }}
            />
          </MuiBox>

          <MuiBox
            sx={{
              width: { xs: '95%', sm: '90%', md: '80%', lg: '80%' },
              margin: 'auto',
            }}>
            <Divider>
              <Chip
                label={id}
                size='large'
              />
            </Divider>

            <MuiBox
              sx={{
                maxWidth: { xs: 320, sm: 480, md: 700, lg: 900 },
                backgroundColor: '#6666660f',
                display: 'block',
                margin: '2rem auto',
              }}>
              <AppBar
                position='static'
                sx={{ backgroundColor: yellowColor }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons='auto'
                  selectionFollowsFocus
                  textColor='inherit'
                  indicatorColor='white'
                  allowScrollButtonsMobile
                  aria-label='scrollable force tabs example'
                  sx={{
                    '& .MuiButtonBase-root': {
                      transition: 'all 0.5s ease',
                      letterSpacing: '1px',
                      fontSize: '1rem',
                    },
                    '& .Mui-selected': {
                      color: '#fff',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      textUnderlineOffset: '5px',
                    },
                  }}>
                  {state?.tabForMap?.map((item, index) => (
                    <Tab
                      key={uuidv4()}
                      label={item?.tabName}
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </AppBar>
              <div>
                {state?.tabForMap?.map((data, indexNum) => (
                  <TabPanel
                    key={uuidv4()}
                    value={value}
                    index={indexNum}>
                    {Array.isArray(data?.textTitle) ? (
                      <ul className='unList'>
                        {data?.textTitle.map((listItem) => (
                          <li
                            key={uuidv4()}
                            className='listStyleType'
                            style={{
                              fontWeight: 'bold',
                              color: greyColorText,
                            }}>
                            {listItem}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <MuiTypography
                        text={data.textTitle}
                        sx={{
                          color: greyColorText,
                          fontWeight: 'bold',
                          fontSize: {
                            xs: '1rem',
                            sm: '1rem',
                            md: '1.1rem',
                            lg: '1.2rem',
                          },
                          textAlign: 'left',
                          marginBottom: '1.5rem',
                        }}
                      />
                    )}
                    <MuiGrid
                      container
                      sx={{
                        padding: '1.5rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                        height: '100%',
                      }}>
                      {data?.cards?.map((itemCard, index) => (
                        <MuiGrid
                          xs={12}
                          sm={12}
                          md={5.8}
                          lg={5.8}
                          xl={5.8}
                          item='true'
                          data-aos='zoom-in'
                          data-aos-easing='linear'
                          data-aos-duration='500'
                          id={`idOdGrida${index}`}
                          key={uuidv4()}
                          sx={{
                            padding: '1rem',
                            boxShadow: '0 0 6px 3px #0000006b',
                            borderRadius: '0.5rem',
                            marginBottom: '2rem',
                            position: 'relative',
                            zIndex: 5,
                            height: {
                              xs: '100%',
                              sm: '100%',
                              md: heightOfDivCards,
                              lg: heightOfDivCards,
                            },
                            backgroundColor: '#fff',
                            transition: 'all 0.5s ease',
                            '&:hover': {
                              zIndex: 10,
                              transform: 'scale(1.15) !important',
                            },
                          }}>
                          <MuiTypography
                            text={itemCard?.title}
                            sx={{
                              color: greyColorText,
                              fontWeight: 'bold',
                              fontSize: {
                                xs: '1rem',
                                sm: '1rem',
                                md: '1.1rem',
                                lg: '1.2rem',
                              },
                              textAlign: 'left',
                              marginBottom: '1.5rem',
                            }}
                          />

                          <ul className='unList'>
                            {itemCard?.list?.map((listItem) => (
                              <li
                                key={uuidv4()}
                                className='listStyleType'>
                                {listItem}
                              </li>
                            ))}
                          </ul>
                        </MuiGrid>
                      ))}
                    </MuiGrid>
                  </TabPanel>
                ))}
              </div>
            </MuiBox>
          </MuiBox>
          <DriveParkSLider />
          <MuiTypography
            text='Više info na broj 064/36-20-725'
            sx={{
              color: yellowColor,
              fontWeight: 'bold',
              fontSize: {
                xs: '1.2rem',
                sm: '1.4rem',
                md: '1.7rem',
                lg: '1.8rem',
              },
              textAlign: 'center',
              margin: '1.5rem auto',
            }}
          />
          <MuiBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '2rem',
            }}>
            <MuiButton
              variant='outlined'
              text='PRIJAVI SE ONLINE'
              size='large'
              onClick={() => navigate('/checkIn')}
              sx={{
                color: redColor,
                borderColor: redColor,
                fontWeight: 'bold',
                '&:hover': {
                  borderColor: redColor,
                  boxShadow: `0 0 5px 1px ${redColor}`,
                },
              }}
            />
          </MuiBox>
        </MuiBox>
      )}
    </>
  );
};

export default Categories;
