import React from 'react';
import MuiBox from '../../../reusableComponents/MuiBox';
import MuiTypography from '../../../reusableComponents/MuiTypography';
import MuiButton from '../../../reusableComponents/MuiButton';
import { greyColorText, redColor, yellowColor } from '../../../helper/colors';
import MuiGrid from '../../../reusableComponents/MuiGrid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import mainOffice from '../../../assets/ucionica/mainOffice.webp';
import { Helmet } from 'react-helmet';

const SigninUp = () => {
  const navigate = useNavigate();
  return (
    <MuiBox sx={{ padding: '0 0.5rem' }}>
      <Helmet>
        <title>Upis u Auto školu - Auto škola DUŠAN START</title>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Saznajte kako da se upišete u Auto školu DUŠAN START u Smederevu. Ova stranica pruža sve potrebne informacije o dokumentaciji, postupku upisa i opcijama za različite kategorije vozačkih dozvola.'
        />
        <meta
          name='keywords'
          content='auto škola, Dušan Start, Smederevo, upis u auto školu, vozačka dozvola, dokumentacija za vozača, postupak upisa, vozačke kategorije'
        />
        <meta
          name='author'
          content='Marko Stojanovic'
        />
        <link
          rel='canonical'
          href='https://www.dusanstartautoskola.rs/signinUp'
        />

        <meta
          property='og:title'
          content='Upis u Auto školu - Auto škola DUŠAN START'
        />
        <meta
          property='og:description'
          content='Saznajte kako da se upišete u Auto školu DUŠAN START u Smederevu. Ova stranica pruža sve potrebne informacije o dokumentaciji, postupku upisa i opcijama za različite kategorije vozačkih dozvola.'
        />
        <meta
          property='og:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          property='og:url'
          content='https://www.dusanstartautoskola.rs/signinUp'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:site_name'
          content='Auto škola DUŠAN START'
        />
        <meta
          property='og:locale'
          content='sr_RS'
        />

        <meta
          name='twitter:card'
          content='summary_large_image'
        />
        <meta
          name='twitter:title'
          content='Upis u Auto školu - Auto škola DUŠAN START'
        />
        <meta
          name='twitter:description'
          content='Saznajte kako da se upišete u Auto školu DUŠAN START u Smederevu. Ova stranica pruža sve potrebne informacije o dokumentaciji, postupku upisa i opcijama za različite kategorije vozačkih dozvola.'
        />
        <meta
          name='twitter:image'
          content='https://www.dusanstartautoskola.rs/logo512.png' // Uključiti stvarni URL
        />
        <meta
          name='twitter:site'
          content='@twitterhandle'
        />
        <meta
          name='twitter:creator'
          content='@twitterhandle'
        />
      </Helmet>

      <MuiBox
        sx={{
          backgroundImage: `url(${mainOffice})`,
          backgroundSize: 'cover',
          height: { xs: '400px', sm: '500px', md: '600px', lg: '600px' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '0.5rem',
        }}>
        <MuiTypography
          text='Obuka vozača'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        />
        <MuiTypography
          text='Vozački ispit'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem', lg: '2.3rem' },
            textAlign: 'center',
            marginBottom: '1.5rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        />
        <MuiTypography
          text='Auto Škola DUŠAN START veruje da svaki kandidat zaslužuje kvalitetan i profesionalan program obuke kako bi postao vrhunski vozač.'
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.5rem', lg: '2rem' },
            textAlign: 'center',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        />
      </MuiBox>
      <MuiBox
        sx={{
          width: {
            xs: '95%',
            sm: '90%',
            md: '80%',
            lg: '80%',
          },
          margin: '2rem auto',
        }}>
        <MuiTypography
          text='USLOVI KOJE MORA DA ISPUNJAVA KANDIDAT PRILIKOM UPISA:'
          sx={{
            color: yellowColor,
            fontSize: {
              xs: '1.4rem',
              sm: '1.6rem',
              md: '1.8rem',
              lg: '2rem',
            },
            textAlign: 'center',
            marginBottom: '1.6rem',
            fontWeight: 'bold',
          }}
        />
        <MuiTypography
          text='Kandidat prilikom upisa može da ima najmanje 16 godina, ali do navršenih 17 godina ne može da polaže praktični ispit.'
          sx={fontText}
        />
        <MuiTypography
          text='Prilikom upisa u auto školu, kandidatu je potrebna lična karta'
          sx={fontText}
        />
        <MuiTypography
          text='Ako kandidat ima neku kategoriju, treba dozvolu od te kategorije da donese na uvid'
          sx={fontText}
        />
        <MuiTypography
          text='Ukoliko je kandidat maloletno lice, ugovor potpisuje roditelj'
          sx={fontText}
        />
        <MuiTypography
          text='Nakon unošenja kandidata u sistem, on može da počne sa teorijskom obukom.'
          sx={fontText}
        />
      </MuiBox>

      <MuiGrid
        container
        sx={{
          padding: '1.5rem',
          justifyContent: 'space-around',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <MuiGrid
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{ marginBottom: '1rem' }}>
          <MuiButton
            variant='outlined'
            text='A kategorija'
            size='large'
            onClick={() => navigate('/categories/A')}
            endIcon={<ArrowForwardIcon />}
            sx={{
              color: yellowColor,
              borderColor: yellowColor,
              fontWeight: 'bold',
              margin: 'auto',
              display: 'flex',
              alignItems: 'normal',
              '&:hover': {
                borderColor: yellowColor,
                boxShadow: `0 0 5px 1px ${yellowColor}`,
              },
            }}
          />
        </MuiGrid>
        <MuiGrid
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{ marginBottom: '1rem' }}>
          <MuiButton
            variant='outlined'
            text='B kategorija'
            size='large'
            onClick={() => navigate('/categories/B')}
            endIcon={<ArrowForwardIcon />}
            sx={{
              color: yellowColor,
              borderColor: yellowColor,
              fontWeight: 'bold',
              margin: 'auto',
              display: 'flex',
              alignItems: 'normal',
              '&:hover': {
                borderColor: yellowColor,
                boxShadow: `0 0 5px 1px ${yellowColor}`,
              },
            }}
          />
        </MuiGrid>
        <MuiGrid
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{ marginBottom: '1rem' }}>
          <MuiButton
            variant='outlined'
            text='C kategorija'
            size='large'
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate('/categories/C')}
            sx={{
              color: yellowColor,
              borderColor: yellowColor,
              fontWeight: 'bold',
              margin: 'auto',
              display: 'flex',
              alignItems: 'normal',
              '&:hover': {
                borderColor: yellowColor,
                boxShadow: `0 0 5px 1px ${yellowColor}`,
              },
            }}
          />
        </MuiGrid>
      </MuiGrid>
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
        }}>
        <MuiButton
          variant='outlined'
          text='PRIJAVI SE ONLINE'
          size='large'
          onClick={() => navigate('/checkIn')}
          sx={{
            color: redColor,
            borderColor: redColor,
            fontWeight: 'bold',
            '&:hover': {
              borderColor: redColor,
              boxShadow: `0 0 5px 1px ${redColor}`,
            },
          }}
        />
      </MuiBox>
    </MuiBox>
  );
};

export default SigninUp;

let fontText = {
  color: greyColorText,
  fontWeight: 400,
  fontSize: {
    xs: '0.9rem',
    sm: '1rem',
    md: '1.3rem',
    lg: '1.3rem',
  },
  textAlign: 'center',
  marginBottom: '1rem',
  letterSpacing: '1px',
};
